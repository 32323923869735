#main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#portfolio-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.item-wrap {
  height: 175px;
  width: 215px;
  margin-right: 60px;
}

.img {
  height: 175px;
  width: 215px;
}
